/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AdelPrivateConfiguration as __Configuration } from '../adel-private-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetSessionAliveResponse } from '../models/get-session-alive-response';
@Injectable({
  providedIn: 'root',
})
class SessionService extends __BaseService {
  static readonly postSessionPath = '/session';
  static readonly getSessionAlivePath = '/session/alive';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create or update session of user
   * @param params The `SessionService.PostSessionParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   */
  postSessionResponse(params: SessionService.PostSessionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XClientId != null) __headers = __headers.set('X-ClientId', params.XClientId.toString());
    if (params.XCaseId != null) __headers = __headers.set('X-CaseId', params.XCaseId.toString());
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/session`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create or update session of user
   * @param params The `SessionService.PostSessionParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   */
  postSession(params: SessionService.PostSessionParams): __Observable<null> {
    return this.postSessionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get session if is alive
   * @param params The `SessionService.GetSessionAliveParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return Get state of session is alive / expired
   */
  getSessionAliveResponse(params: SessionService.GetSessionAliveParams): __Observable<__StrictHttpResponse<GetSessionAliveResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XClientId != null) __headers = __headers.set('X-ClientId', params.XClientId.toString());
    if (params.XCaseId != null) __headers = __headers.set('X-CaseId', params.XCaseId.toString());
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/session/alive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetSessionAliveResponse>;
      })
    );
  }
  /**
   * Get session if is alive
   * @param params The `SessionService.GetSessionAliveParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return Get state of session is alive / expired
   */
  getSessionAlive(params: SessionService.GetSessionAliveParams): __Observable<GetSessionAliveResponse> {
    return this.getSessionAliveResponse(params).pipe(
      __map(_r => _r.body as GetSessionAliveResponse)
    );
  }
}

module SessionService {

  /**
   * Parameters for postSession
   */
  export interface PostSessionParams {

    /**
     * Unique identification of authorized application"
     */
    XClientId: string;

    /**
     * Identification of the given case
     */
    XCaseId: string;

    /**
     * Tracking ID for logging
     */
    XTrackingId?: string;
  }

  /**
   * Parameters for getSessionAlive
   */
  export interface GetSessionAliveParams {

    /**
     * Unique identification of authorized application"
     */
    XClientId: string;

    /**
     * Identification of the given case
     */
    XCaseId: string;

    /**
     * Tracking ID for logging
     */
    XTrackingId?: string;
  }
}

export { SessionService }
