import { AppEnvironment } from '~core/models/app-environment.model';
import { Solution } from '~core/enums/solution.enum';

export const environment: AppEnvironment = {
  solution: Solution.DELOITTE,
  production: true,
  hosts: {
    adelBackend: 'https://adel-production.appspot.com',
    serviceProvider: 'https://adel-production.appspot.com'
  },
  mockBE: false,
  mockSP: false,
  urlSP: 'https://deloitte.adelsolution.eu/sp-mock',
  apiKey: 'AIzaSyARZPKJTalzIfM1agyZJx9WTVXrXR2Twtw',
  projectId: 'adel-production',
  spToken:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL3BsYW5hci1zdW5yaXNlLTI4MjExMS5hcHBzcG90LmNvbSIsImlzcyI6ImFkZWwtdGVzdEBwbGFuYXItc3VucmlzZS0yODIxMTEuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20ifQ.Yl4r8yV1tP05bHYQFOiEikHHQqd4Z27TYthH9kYFqN1Kd2qSngf207YrSUOqUkaZ7qEEl2SfZSTHhUQfLGyge9IxLXgqMc76i_ZRF4tBvtAYA6z47m7RVcOjruPCD5w4sTeBjdDiqzU_ItRb0PXaV_RGn4u7E-idj_8Eynn3CzqPhthAn5k1EVFKu3nGvOSLquE36oxN6wa26zWG1o5Ri-EQSZVwtKk3DV4opd_Goh3HzogDEImMz4Dusk53kaB_zeFQnHeYyM9pbNf1GF_RubWiXKzXP6dxEMWRlGIy6vyJypz2eLy4dwRitgs4xcdDl7IeGpzqhrXdX_wyL36saw'
};
