import { Injectable } from '@angular/core';
import { AdelError } from '../models/adel-error.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  static createAdelError(code: string, message: string): AdelError {
    return {
      error: { code, message }
    };
  }
}
