/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AdelSpConfiguration, AdelSpConfigurationInterface } from './adel-sp-configuration';

import { CasesService } from './services/cases.service';

/**
 * Provider for all adel-sp services, plus AdelSpConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AdelSpConfiguration,
    CasesService
  ],
})
export class AdelSpModule {
  static forRoot(customParams: AdelSpConfigurationInterface): ModuleWithProviders<AdelSpModule> {
    return {
      ngModule: AdelSpModule,
      providers: [
        {
          provide: AdelSpConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
