import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '~env/environment';

// Debug page on production environment is forbidden
const routes: Routes = [
  ...(environment.production
    ? []
    : [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'debug'
        }
      ]),
  ...(environment.production
    ? [
        {
          path: 'debug1537428642',
          loadChildren: () =>
            import('./pages/debug-page/debug-page.module').then(
              a => a.DebugPageModule
            )
        }
      ]
    : [
        {
          path: 'debug',
          loadChildren: () =>
            import('./pages/debug-page/debug-page.module').then(
              a => a.DebugPageModule
            )
        }
      ]),
  {
    path: 'sp-mock',
    loadChildren: () =>
      import('./pages/sp-mock/sp-mock.module').then(a => a.SpMockModule)
  },
  {
    path: 'user_authenticated',
    redirectTo: 'case-flow/account-selection'
  },
  {
    path: 'payment_authenticated',
    loadChildren: () =>
      import(
        './pages/payment-authenticated/payment-authenticated.module'
      ).then(a => a.PaymentAuthenticatedModule)
  },
  {
    path: 'case-flow',
    loadChildren: () =>
      import('./pages/case-flow/case-flow.module').then(
        a => a.CaseFlowModule
      )
  },
  {
    path: 'consents',
    loadChildren: () =>
      import('./pages/consents/consents.module').then(
        a => a.ConsentsModule
      )
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        a => a.PageNotFoundModule
      )
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
