<adl-background>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center stretch"
    ngClass.xs="app-xs"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="stretch stretch"
      fxFlex.lt-lg="*"
      fxFlex.gt-md="1332px"
      class="mat-typography"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</adl-background>
