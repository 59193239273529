import { Injectable } from '@angular/core';

import { environment } from '~env/environment';
import { LogLevel } from '~core/models/log-level.model';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private readonly _logLevel: LogLevel;

  constructor() {
    this._logLevel = environment.logLevel;
  }

  error(data: any): void {
    if (
      this._logLevel === 'error' ||
      this._logLevel === 'warn' ||
      this._logLevel === 'info' ||
      this._logLevel === 'debug'
    ) {
      console.error(data);
    }
  }

  warn(data: any): void {
    if (
      this._logLevel === 'warn' ||
      this._logLevel === 'info' ||
      this._logLevel === 'debug'
    ) {
      console.warn(data);
    }
  }

  info(data: any): void {
    if (this._logLevel === 'info' || this._logLevel === 'debug') {
      console.info(data);
    }
  }

  debug(data: any): void {
    if (this._logLevel === 'debug') {
      console.debug(data);
    }
  }
}
