import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'deloitte-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements AfterViewInit {
  constructor(private cdref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdref.detectChanges();
  }
}
