import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageValue } from '~core/enums/storage-value.enum';

import { LogService } from '~core/services/log.service';
import { StorageService } from '~core/services/storage.service';
import { environment } from '~env/environment';
import { CasesService } from '~api/adel-sp/services/cases.service';

@Injectable({
  providedIn: 'root'
})
export class LocaleInterceptor implements HttpInterceptor {
  constructor(
    private readonly _log: LogService,
    private readonly _storage: StorageService,
    private readonly _localeService: TranslocoService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // If is ser read and use accept language
    const acceptLanguage = this._storage.getItem(
      StorageValue.ACCEPT_LANGUAGE
    );
    if (acceptLanguage) {
      req = req.clone({
        headers: req.headers.set('Accept-Language', acceptLanguage)
      });
    }
    if (!req.url.includes(environment.hosts.adelBackend)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // when it isn't case/create service / first request
          // we do not store language for a page
          if (event.url.indexOf(CasesService.createCasePath) < 0) {
            return event;
          }
          const recievedLocale = event.headers.get('Accept-Language');

          if (recievedLocale) {
            this._storage.setItem(
              StorageValue.ACCEPT_LANGUAGE,
              recievedLocale.substring(0, 2)
            );
          }

          const activeLang = this._storage.getItem(
            StorageValue.ACCEPT_LANGUAGE
          );

          if (activeLang) {
            this._log.debug(`Setting active language: "${activeLang}"`);
            this._localeService.setActiveLang(activeLang);
            return event;
          }

          this._localeService.setActiveLang('en');
          return event;
        }
      })
    );
  }
}
