import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '~env/environment';
import { StorageValue } from '~core/enums/storage-value.enum';
import { StorageService } from '~core/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionInterceptor implements HttpInterceptor {
  constructor(private readonly _storage: StorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * Condition that doesn't allow private credentials to leak
     * from ADEL backend. `X-ClientId` and `x-trackingid` must be
     * transfered only to ADEL backend!
     */
    if (!req.url.includes(environment.hosts.adelBackend)) {
      return next.handle(req);
    }

    req = req.clone({
      setHeaders: {
        // Overwrite X-ClientId only when doesn't exists
        'X-ClientId':
          req.headers.get('X-ClientId') ||
          this._storage.getItem(StorageValue.X_CLIENT_ID) ||
          '',
        'x-caseid': this._storage.getItem(StorageValue.CASE_ID) || '',
        'x-trackingid':
          this._storage.getItem(StorageValue.X_TRACKING_ID) || ''
      }
    });

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this._storage.setItem(
            StorageValue.X_TRACKING_ID,
            event.headers.get('x-trackingid')
          );
        }
        return event;
      })
    );
  }
}
