import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';

import { TranslocoModule } from '@ngneat/transloco';
import { LoadingComponent } from './components/loading/loading.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CoreModule } from '~core/core.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslocoModule,
    MatStepperModule,
    CoreModule
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    ClickOutsideDirective,
    LoadingComponent,
    StepperComponent
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'cs-CZ' }],
  declarations: [
    ClickOutsideDirective,
    LoadingComponent,
    StepperComponent
  ]
})
export class SharedModule {}
