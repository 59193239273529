import { ErrorHandler, NgModule } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

import { AdelPrivateModule } from '~api/adel-private/adel-private.module';
import { AdelSpModule } from '~api/adel-sp/adel-sp.module';
import { environment } from '~env/environment';
import { ErrorsInterceptor } from '~core/interceptors/errors.interceptor';
import { LocaleInterceptor } from '~core/interceptors/locale.interceptor';
import { MocksInterceptor } from '~core/interceptors/mocks.interceptor';
import { SessionInterceptor } from '~core/interceptors/session.interceptor';
import { GlobalErrorHandler } from '~core/global-error-handler';
import { HeaderComponent } from './components/header/header.component';
import { ZaploModule } from '~solutions/zaplo/zaplo.module';
import { CommonModule } from '@angular/common';
import { DeloitteModule } from '~solutions/deloitte/deloitte.module';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { BackgroundComponent } from '~core/components/background/background.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ProgressBarComponent,
    BackgroundComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ZaploModule,
    DeloitteModule,
    AdelPrivateModule.forRoot({
      rootUrl: environment.hosts.adelBackend
    }),
    AdelSpModule.forRoot({
      rootUrl: environment.hosts.serviceProvider
    })
  ],
  exports: [HeaderComponent, ProgressBarComponent, BackgroundComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MocksInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class CoreModule {}
