import { environment } from '~env/environment';
import { Solution } from '~core/enums/solution.enum';

export const isZaplo = (): boolean =>
  environment.solution === Solution.ZAPLO;

export const isDeloitte = (): boolean =>
  environment.solution === Solution.DELOITTE;

export const goTo = (url: string): void => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('referrerpolicy', 'strict-origin');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
};
