import { Injectable } from '@angular/core';

import { StorageValue } from '../enums/storage-value.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  get storage(): Storage {
    return window.sessionStorage;
  }

  setItem(name: StorageValue, value: string): void {
    this.storage.setItem(name, value);
  }

  getItem(name: StorageValue): string {
    return this.storage.getItem(name);
  }
}
