<zaplo-background *ngIf="isZaplo()">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</zaplo-background>
<deloitte-background *ngIf="isDeloitte()">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</deloitte-background>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
