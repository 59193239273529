export enum StorageValue {
  /**
   * Lenguage defined by Service Provider
   */
  ACCEPT_LANGUAGE = 'ACCEPT_LANGUAGE',
  /**
   * If is set value, AISP is defined
   * when is empty AISP isn't defined
   */
  AISP = 'AISP',
  /**
   * If is set value, PISP is defined
   * when is empty PISP isn't defined
   */
  PISP = 'PISP',
  /**
   * Url where should be user redirected for payment
   */
  PAYMENT_REDIRECT_URI = 'PAYMENT_REDIRECT_URI',
  /**
   * Redirect uri recieved from Service Provider
   */
  SP_REDIRECT_URI = 'SP_REDIRECT_URI',
  /**
   * Value which define if is bank account processing/processed
   */
  IS_BANK_ACCOUNTS_PROCESS = 'IS_BANK_ACCOUNTS_PROCESS',
  X_CLIENT_ID = 'X_CLIENT_ID',
  CASE_ID = 'CASE_ID',
  /**
   * Name of the user
   */
  NAME = 'NAME',
  /**
   * Surname of the user
   */
  SURNAME = 'SURNAME',
  X_TRACKING_ID = 'X_TRACKING_ID',
  /**
   * Amount of payment for pisp
   */
  AMOUNT = 'AMOUNT'
}
