import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '~env/environment';
import { LogService } from '~core/services/log.service';
import { InformationService } from '~core/services/information.service';
import { StorageService } from '~core/services/storage.service';
import { END_OF_PROCESS_ERRORS } from '~core/constants/errors-end-of-process.const';
import { TRY_AGAIN_LATER_ERRORS } from '~core/constants/errors-try-again-later.const';
import { StorageValue } from '~core/enums/storage-value.enum';
import { Router } from '@angular/router';
import { ERRORS_TRY_ACCOUNT_SELECTION } from '~core/constants/errors-try-account-selection';
import { ServiceProviderService } from '~core/services/service-provider.service';
import { ErrorService } from '~core/services/error.service';
import { AdelError } from '~core/models/adel-error.model';
import { Errors } from '~core/enums/errors.enum';

@Injectable({
  providedIn: 'root'
})
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private readonly _log: LogService,
    private readonly _storage: StorageService
  ) {}

  intercept(
    req: HttpRequest<HttpEvent<any>>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._log.debug(`Request url - ${req.url}`);

    if (!req.url.includes(environment.hosts.adelBackend)) {
      return next.handle(req);
    }

    /**
     * Debug page error, never appears on prod
     */
    if (req.url.includes('/case/create')) {
      return next.handle(req).pipe(
        catchError((errResp: HttpErrorResponse) => {
          const {
            code: errorCode,
            message: errorMessage
          } = errResp.error.error;

          this._log.error(
            `Can't create case. Code: ${errResp.error?.error?.code}, message: ${errResp.error?.error?.message}`
          );

          return throwError(
            ErrorService.createAdelError(
              errorCode,
              JSON.stringify(errResp)
            )
          );
        })
      );
    }

    return next.handle(req).pipe(
      catchError((errResp: HttpErrorResponse) => {
        const adelError = this.getAdelError(errResp);
        if (adelError) {
          return throwError(adelError);
        }
        // Default unknown error
        const unknowError = ErrorService.createAdelError(
          Errors.UNKNOWN,
          JSON.stringify(errResp)
        );
        return throwError(unknowError);
      })
    );
  }

  private getAdelError(errResp: HttpErrorResponse): AdelError {
    const caseId = this._storage.getItem(StorageValue.CASE_ID);
    const {
      code: errorCode,
      message: errorMessage
    } = errResp.error?.error;
    // If is set errorCode crate adel error, otherwise return null
    if (errorCode) {
      return ErrorService.createAdelError(errorCode, errorMessage);
    }
    return null;
  }
}
