import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { StorageValue } from '../enums/storage-value.enum';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ServiceProviderService {
  constructor(
    private readonly _storage: StorageService,
    private readonly _router: Router
  ) {}

  redirectToSP(
    caseId: string,
    errorCode: string,
    errorMessage: string
  ): void {
    const spRedirectUri = this._storage.getItem(
      StorageValue.SP_REDIRECT_URI
    );
    // In same situations redirect uri doesn't have to be defined
    // e.g. consents page
    if (
      spRedirectUri &&
      window.location.href.indexOf(spRedirectUri) < 0
    ) {
      location.replace(
        `${spRedirectUri}?caseId=${caseId}&errorCode=${errorCode}&errorMessage=${errorMessage}`
      );
    }
  }
}
