/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AdelPrivateConfiguration, AdelPrivateConfigurationInterface } from './adel-private-configuration';

import { AccountsService } from './services/accounts.service';
import { PaymentsService } from './services/payments.service';
import { CasesService } from './services/cases.service';
import { SessionService } from './services/session.service';

/**
 * Provider for all adel-private services, plus AdelPrivateConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AdelPrivateConfiguration,
    AccountsService,
    PaymentsService,
    CasesService,
    SessionService
  ],
})
export class AdelPrivateModule {
  static forRoot(customParams: AdelPrivateConfigurationInterface): ModuleWithProviders<AdelPrivateModule> {
    return {
      ngModule: AdelPrivateModule,
      providers: [
        {
          provide: AdelPrivateConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
