import { Component, OnInit } from '@angular/core';
import * as utils from '~solutions/utils';

@Component({
  selector: 'adl-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {
  ngOnInit(): void {}

  isZaplo = (): boolean => utils.isZaplo();
  isDeloitte = (): boolean => utils.isDeloitte();
}
