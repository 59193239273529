import { ErrorHandler, Injectable } from '@angular/core';
import { AdelError } from '~core/models/adel-error.model';
import { END_OF_PROCESS_ERRORS } from '~core/constants/errors-end-of-process.const';
import { ServiceProviderService } from '~core/services/service-provider.service';
import { StorageService } from '~core/services/storage.service';
import { StorageValue } from '~core/enums/storage-value.enum';
import { TRY_AGAIN_LATER_ERRORS } from '~core/constants/errors-try-again-later.const';
import { ERRORS_TRY_ACCOUNT_SELECTION } from '~core/constants/errors-try-account-selection';
import { Router } from '@angular/router';
import { Errors } from '~core/enums/errors.enum';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import { environment } from '~env/environment';
import { ERRORS_DO_NOTHING } from '~core/constants/errors-do-nothing';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {
  private stackdriver = null;

  constructor(
    private readonly _storageService: StorageService,
    private readonly _serviceProvider: ServiceProviderService,
    private readonly _router: Router
  ) {
    super();
    this.stackdriver = new StackdriverErrorReporter();
    this.stackdriver.start({
      key: environment.apiKey,
      projectId: environment.projectId
    });
  }

  handleError = async (inError: any | AdelError) => {
    this.stackdriver.report(
      `FE error occurred: ${JSON.stringify(inError)}`
    );
    // If it is AdelError is defined code and message
    const {
      error: { code, message }
    } = inError;
    const caseId = this._storageService.getItem(StorageValue.CASE_ID);
    // Redirect to SP page
    if (
      END_OF_PROCESS_ERRORS.includes(code) ||
      TRY_AGAIN_LATER_ERRORS.includes(code) ||
      [Errors.UNKNOWN].includes(code)
    ) {
      this._serviceProvider.redirectToSP(caseId, code, message);
    }
    // Redirect to page with account selection
    else if (ERRORS_TRY_ACCOUNT_SELECTION.includes(code)) {
      console.log('Redirect!!!');
      await this._router.navigate(['case-flow', 'account-selection'], {
        queryParams: {
          error: code,
          message
        }
      });
    } else if (!ERRORS_DO_NOTHING.includes(code)) {
      // By default return to SP page
      this._serviceProvider.redirectToSP(caseId, code, message);
    }
  };
}
