/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AdelSpConfiguration as __Configuration } from '../adel-sp-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CaseConsentsRequest } from '../models/case-consents-request';
import { CaseCreateResponse } from '../models/case-create-response';
import { CaseCreateRequest } from '../models/case-create-request';
import { GetResultResponse } from '../models/get-result-response';
import { GetResultRequest } from '../models/get-result-request';
import { GetTransactionHistoryResponse } from '../models/get-transaction-history-response';
import { GetTransactionHistoryRequest } from '../models/get-transaction-history-request';
@Injectable({
  providedIn: 'root',
})
class CasesService extends __BaseService {
  static readonly caseConsentsPath = '/case/consents';
  static readonly createCasePath = '/case/create';
  static readonly processCasePath = '/case/process';
  static readonly getResultPath = '/case/get-result';
  static readonly getTransactionHistoryPath = '/case/get-transaction-history';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Add consents (with AISP, PISP) for case defined by external case id
   * @param caseConsentsRequest undefined
   */
  caseConsentsResponse(caseConsentsRequest?: CaseConsentsRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = caseConsentsRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/case/consents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Add consents (with AISP, PISP) for case defined by external case id
   * @param caseConsentsRequest undefined
   */
  caseConsents(caseConsentsRequest?: CaseConsentsRequest): __Observable<null> {
    return this.caseConsentsResponse(caseConsentsRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Initialize ADEL case processing
   *
   * Service for initiation ADEL case processing. Service is called by registered service provider to initiate processing one of the services.
   * @param params The `CasesService.CreateCaseParams` containing the following parameters:
   *
   * - `Authorization`: Authorization bearer token
   *
   * - `Accept-Language`: Preferred language of the given user
   *
   * - `caseCreateRequest`:
   *
   * @return Operation was successfuly finished
   */
  createCaseResponse(params: CasesService.CreateCaseParams): __Observable<__StrictHttpResponse<CaseCreateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AcceptLanguage != null) __headers = __headers.set('Accept-Language', params.AcceptLanguage.toString());
    __body = params.caseCreateRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/case/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CaseCreateResponse>;
      })
    );
  }
  /**
   * Initialize ADEL case processing
   *
   * Service for initiation ADEL case processing. Service is called by registered service provider to initiate processing one of the services.
   * @param params The `CasesService.CreateCaseParams` containing the following parameters:
   *
   * - `Authorization`: Authorization bearer token
   *
   * - `Accept-Language`: Preferred language of the given user
   *
   * - `caseCreateRequest`:
   *
   * @return Operation was successfuly finished
   */
  createCase(params: CasesService.CreateCaseParams): __Observable<CaseCreateResponse> {
    return this.createCaseResponse(params).pipe(
      __map(_r => _r.body as CaseCreateResponse)
    );
  }

  /**
   * Process redirect on Adel frontend
   *
   * After creation of the given case must be this process finalize by calling service to redirect on Adel frontend
   * @param params The `CasesService.ProcessCaseParams` containing the following parameters:
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * - `Authorization`: Authorization bearer token
   *
   * - `spRedirectUri`: Service provider redirect URI after process is finished
   *
   * - `caseId`: The case id
   */
  processCaseResponse(params: CasesService.ProcessCaseParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.spRedirectUri != null) __params = __params.set('spRedirectUri', params.spRedirectUri.toString());
    if (params.caseId != null) __params = __params.set('caseId', params.caseId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/case/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Process redirect on Adel frontend
   *
   * After creation of the given case must be this process finalize by calling service to redirect on Adel frontend
   * @param params The `CasesService.ProcessCaseParams` containing the following parameters:
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * - `Authorization`: Authorization bearer token
   *
   * - `spRedirectUri`: Service provider redirect URI after process is finished
   *
   * - `caseId`: The case id
   */
  processCase(params: CasesService.ProcessCaseParams): __Observable<null> {
    return this.processCaseResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Result of the given case
   *
   * Gets result about processing of the given case.
   * @param params The `CasesService.GetResultParams` containing the following parameters:
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * - `Authorization`: Authorization bearer token
   *
   * - `getResultRequest`:
   *
   * @return Operation was successfuly finished
   */
  getResultResponse(params: CasesService.GetResultParams): __Observable<__StrictHttpResponse<GetResultResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.getResultRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/case/get-result`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetResultResponse>;
      })
    );
  }
  /**
   * Result of the given case
   *
   * Gets result about processing of the given case.
   * @param params The `CasesService.GetResultParams` containing the following parameters:
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * - `Authorization`: Authorization bearer token
   *
   * - `getResultRequest`:
   *
   * @return Operation was successfuly finished
   */
  getResult(params: CasesService.GetResultParams): __Observable<GetResultResponse> {
    return this.getResultResponse(params).pipe(
      __map(_r => _r.body as GetResultResponse)
    );
  }

  /**
   * Gets transaction history for the given account
   *
   * Gets transaction history for the given account (supported paging)
   * @param params The `CasesService.GetTransactionHistoryParams` containing the following parameters:
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * - `Authorization`: Authorization bearer token
   *
   * - `getTransactionHistoryRequest`:
   *
   * @return Operation was successfuly finished
   */
  getTransactionHistoryResponse(params: CasesService.GetTransactionHistoryParams): __Observable<__StrictHttpResponse<GetTransactionHistoryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.getTransactionHistoryRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/case/get-transaction-history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetTransactionHistoryResponse>;
      })
    );
  }
  /**
   * Gets transaction history for the given account
   *
   * Gets transaction history for the given account (supported paging)
   * @param params The `CasesService.GetTransactionHistoryParams` containing the following parameters:
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * - `Authorization`: Authorization bearer token
   *
   * - `getTransactionHistoryRequest`:
   *
   * @return Operation was successfuly finished
   */
  getTransactionHistory(params: CasesService.GetTransactionHistoryParams): __Observable<GetTransactionHistoryResponse> {
    return this.getTransactionHistoryResponse(params).pipe(
      __map(_r => _r.body as GetTransactionHistoryResponse)
    );
  }
}

module CasesService {

  /**
   * Parameters for createCase
   */
  export interface CreateCaseParams {

    /**
     * Authorization bearer token
     */
    Authorization: string;

    /**
     * Preferred language of the given user
     */
    AcceptLanguage: string;
    caseCreateRequest?: CaseCreateRequest;
  }

  /**
   * Parameters for processCase
   */
  export interface ProcessCaseParams {

    /**
     * Tracking ID for logging
     */
    XTrackingId: string;

    /**
     * Authorization bearer token
     */
    Authorization: string;

    /**
     * Service provider redirect URI after process is finished
     */
    spRedirectUri?: string;

    /**
     * The case id
     */
    caseId?: string;
  }

  /**
   * Parameters for getResult
   */
  export interface GetResultParams {

    /**
     * Tracking ID for logging
     */
    XTrackingId: string;

    /**
     * Authorization bearer token
     */
    Authorization: string;
    getResultRequest?: GetResultRequest;
  }

  /**
   * Parameters for getTransactionHistory
   */
  export interface GetTransactionHistoryParams {

    /**
     * Tracking ID for logging
     */
    XTrackingId: string;

    /**
     * Authorization bearer token
     */
    Authorization: string;
    getTransactionHistoryRequest?: GetTransactionHistoryRequest;
  }
}

export { CasesService }
