/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AdelPrivateConfiguration as __Configuration } from '../adel-private-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CaseInfo } from '../models/case-info';
import { GetStatusResponse } from '../models/get-status-response';
@Injectable({
  providedIn: 'root',
})
class CasesService extends __BaseService {
  static readonly caseInfoPath = '/case/search-case-info';
  static readonly getStatusPath = '/case/get-status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all possible informations about created case which was be created in first step.
   *
   * Returns informations about case for its ID.
   * @param params The `CasesService.CaseInfoParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return Informations about user
   */
  caseInfoResponse(params: CasesService.CaseInfoParams): __Observable<__StrictHttpResponse<CaseInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XClientId != null) __headers = __headers.set('X-ClientId', params.XClientId.toString());
    if (params.XCaseId != null) __headers = __headers.set('X-CaseId', params.XCaseId.toString());
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/case/search-case-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CaseInfo>;
      })
    );
  }
  /**
   * Gets all possible informations about created case which was be created in first step.
   *
   * Returns informations about case for its ID.
   * @param params The `CasesService.CaseInfoParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return Informations about user
   */
  caseInfo(params: CasesService.CaseInfoParams): __Observable<CaseInfo> {
    return this.caseInfoResponse(params).pipe(
      __map(_r => _r.body as CaseInfo)
    );
  }

  /**
   * Status of the given case
   *
   * Gets status about processing of the given case.
   * @param params The `CasesService.GetStatusParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return Operation was successfuly finished
   */
  getStatusResponse(params: CasesService.GetStatusParams): __Observable<__StrictHttpResponse<GetStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XClientId != null) __headers = __headers.set('X-ClientId', params.XClientId.toString());
    if (params.XCaseId != null) __headers = __headers.set('X-CaseId', params.XCaseId.toString());
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/case/get-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetStatusResponse>;
      })
    );
  }
  /**
   * Status of the given case
   *
   * Gets status about processing of the given case.
   * @param params The `CasesService.GetStatusParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return Operation was successfuly finished
   */
  getStatus(params: CasesService.GetStatusParams): __Observable<GetStatusResponse> {
    return this.getStatusResponse(params).pipe(
      __map(_r => _r.body as GetStatusResponse)
    );
  }
}

module CasesService {

  /**
   * Parameters for caseInfo
   */
  export interface CaseInfoParams {

    /**
     * Unique identification of authorized application"
     */
    XClientId: string;

    /**
     * Identification of the given case
     */
    XCaseId: string;

    /**
     * Tracking ID for logging
     */
    XTrackingId?: string;
  }

  /**
   * Parameters for getStatus
   */
  export interface GetStatusParams {

    /**
     * Unique identification of authorized application"
     */
    XClientId: string;

    /**
     * Identification of the given case
     */
    XCaseId: string;

    /**
     * Tracking ID for logging
     */
    XTrackingId?: string;
  }
}

export { CasesService }
