import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  AccountsList,
  ProcessBankAccountsRes,
  CaseInfo
} from '~api/adel-private/models';

import { environment } from '~env/environment';
import { LogService } from '~core/services/log.service';
import { StorageService } from '~core/services/storage.service';
import { CaseCreateResponse } from '~api/adel-sp/models';
import { StorageValue } from '~core/enums/storage-value.enum';
import { ACCOUNTS_LIST } from '~core/mocks/accounts-list.mock';

@Injectable({
  providedIn: 'root'
})
export class MocksInterceptor implements HttpInterceptor {
  constructor(
    private readonly _log: LogService,
    private readonly _storage: StorageService,
    private readonly _router: Router
  ) {}

  intercept(
    req: HttpRequest<HttpEvent<any>>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.production) {
      return next.handle(req);
    }

    if (environment.mockBE) {
      this._log.debug('Adel Backend mocks activated');
      const beResponse = this.handleAdelBackendCall(req);

      if (beResponse) {
        return beResponse;
      }
    }

    if (environment.mockSP) {
      this._log.debug('Service Provider mocks activated');
      const spResponse = this.handleServiceProviderCall(req);

      if (spResponse) {
        return spResponse;
      }
    }
    return next.handle(req);
  }

  private handleAdelBackendCall(
    req: HttpRequest<any>
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('/accounts/accounts-list')) {
      this._log.debug('Intercepting "/accounts/accounts-list"');

      return of(
        new HttpResponse<AccountsList>({
          body: ACCOUNTS_LIST,
          status: 200
        })
      );
    }

    if (req.url.includes('/accounts/process-bank-accounts')) {
      this._log.debug('Intercepting "/accounts/process-bank-accounts"');

      return of(
        new HttpResponse<ProcessBankAccountsRes>({
          body: { authorizationUri: '' },
          status: 200
        })
      ).pipe(delay(3000));
    }

    if (req.url.includes('/case/search-case-info')) {
      this._log.debug('Intercepting "/case/search-case-info"');

      return of(
        new HttpResponse<CaseInfo>({
          body: { authorizationUri: '' },
          status: 200
        })
      );
    }

    return null;
  }

  private handleServiceProviderCall(
    req: HttpRequest<any>
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('/case/create')) {
      this._log.debug('Intercepting "/case/create"');

      this._storage.setItem(StorageValue.CASE_ID, '195');

      return of(
        new HttpResponse<CaseCreateResponse>({
          body: { caseId: '195' },
          status: 200
        })
      );
    }

    if (req.url.includes('/case/process')) {
      this._log.debug('Intercepting "/case/process"');

      this._storage.setItem(
        StorageValue.X_CLIENT_ID,
        '7aab9942-4643-40a4-89f2-7254a94a3212'
      );

      this._router.navigate(['case-flow', 'account-selection']);

      return of(
        new HttpResponse<null>({
          status: 302
        })
      );
    }

    return null;
  }
}
