/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AdelPrivateConfiguration as __Configuration } from '../adel-private-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccountsList } from '../models/accounts-list';
import { ProcessBankAccountsRes } from '../models/process-bank-accounts-res';
import { ProcessBankAccountsReq } from '../models/process-bank-accounts-req';
@Injectable({
  providedIn: 'root',
})
class AccountsService extends __BaseService {
  static readonly listAccountsPath = '/accounts/accounts-list';
  static readonly processBankAccountsPath = '/accounts/process-bank-accounts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List of accounts.
   *
   * Returns a list of accounts for given case ID.
   * @param params The `AccountsService.ListAccountsParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return All active accounts
   */
  listAccountsResponse(params: AccountsService.ListAccountsParams): __Observable<__StrictHttpResponse<AccountsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XClientId != null) __headers = __headers.set('X-ClientId', params.XClientId.toString());
    if (params.XCaseId != null) __headers = __headers.set('X-CaseId', params.XCaseId.toString());
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/accounts/accounts-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountsList>;
      })
    );
  }
  /**
   * List of accounts.
   *
   * Returns a list of accounts for given case ID.
   * @param params The `AccountsService.ListAccountsParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return All active accounts
   */
  listAccounts(params: AccountsService.ListAccountsParams): __Observable<AccountsList> {
    return this.listAccountsResponse(params).pipe(
      __map(_r => _r.body as AccountsList)
    );
  }

  /**
   * Processing of given choosing bank account
   * @param params The `AccountsService.ProcessBankAccountsParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `processBankAccountReq`:
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return Operation was successfuly finished
   */
  processBankAccountsResponse(params: AccountsService.ProcessBankAccountsParams): __Observable<__StrictHttpResponse<ProcessBankAccountsRes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XClientId != null) __headers = __headers.set('X-ClientId', params.XClientId.toString());
    if (params.XCaseId != null) __headers = __headers.set('X-CaseId', params.XCaseId.toString());
    __body = params.processBankAccountReq;
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/accounts/process-bank-accounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcessBankAccountsRes>;
      })
    );
  }
  /**
   * Processing of given choosing bank account
   * @param params The `AccountsService.ProcessBankAccountsParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `processBankAccountReq`:
   *
   * - `X-TrackingId`: Tracking ID for logging
   *
   * @return Operation was successfuly finished
   */
  processBankAccounts(params: AccountsService.ProcessBankAccountsParams): __Observable<ProcessBankAccountsRes> {
    return this.processBankAccountsResponse(params).pipe(
      __map(_r => _r.body as ProcessBankAccountsRes)
    );
  }
}

module AccountsService {

  /**
   * Parameters for listAccounts
   */
  export interface ListAccountsParams {

    /**
     * Unique identification of authorized application"
     */
    XClientId: string;

    /**
     * Identification of the given case
     */
    XCaseId: string;

    /**
     * Tracking ID for logging
     */
    XTrackingId?: string;
  }

  /**
   * Parameters for processBankAccounts
   */
  export interface ProcessBankAccountsParams {

    /**
     * Unique identification of authorized application"
     */
    XClientId: string;

    /**
     * Identification of the given case
     */
    XCaseId: string;
    processBankAccountReq?: ProcessBankAccountsReq;

    /**
     * Tracking ID for logging
     */
    XTrackingId?: string;
  }
}

export { AccountsService }
