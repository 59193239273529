/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AdelPrivateConfiguration as __Configuration } from '../adel-private-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class PaymentsService extends __BaseService {
  static readonly storeAuthorizationResultPath = '/payments/store-authorization-result';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Stores the result of payment authorization
   * @param params The `PaymentsService.StoreAuthorizationResultParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   */
  storeAuthorizationResultResponse(params: PaymentsService.StoreAuthorizationResultParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.XClientId != null) __headers = __headers.set('X-ClientId', params.XClientId.toString());
    if (params.XCaseId != null) __headers = __headers.set('X-CaseId', params.XCaseId.toString());
    if (params.XTrackingId != null) __headers = __headers.set('X-TrackingId', params.XTrackingId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/payments/store-authorization-result`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Stores the result of payment authorization
   * @param params The `PaymentsService.StoreAuthorizationResultParams` containing the following parameters:
   *
   * - `X-ClientId`: Unique identification of authorized application"
   *
   * - `X-CaseId`: Identification of the given case
   *
   * - `X-TrackingId`: Tracking ID for logging
   */
  storeAuthorizationResult(params: PaymentsService.StoreAuthorizationResultParams): __Observable<null> {
    return this.storeAuthorizationResultResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PaymentsService {

  /**
   * Parameters for storeAuthorizationResult
   */
  export interface StoreAuthorizationResultParams {

    /**
     * Unique identification of authorized application"
     */
    XClientId: string;

    /**
     * Identification of the given case
     */
    XCaseId: string;

    /**
     * Tracking ID for logging
     */
    XTrackingId?: string;
  }
}

export { PaymentsService }
