import { Account } from '~api/adel-private/models';

export const ACCOUNTS_LIST: Account[] = [
  {
    idAccount: null,
    bban: '440050001',
    bankCode: '2250',
    iban: 'CZ3022500000000440050001',
    bic: 'CTASCZ22',
    currency: 'CZK',
    country: 'CZE',
    name: 'Tomáš Novák',
    alias: 'Můj účet'
  },
  {
    idAccount: null,
    bban: '440050001',
    bankCode: '2250',
    iban: 'CZ3322500000000100541314',
    bic: 'CTASCZ22',
    currency: 'CZK',
    country: 'CZE',
    name: 'Tomáš Novák',
    alias: 'Můj spořící účet'
  },
  {
    idAccount: null,
    bban: '440050001',
    bankCode: '2250',
    iban: 'CZ3222500000000100575575',
    bic: 'CTASCZ22',
    currency: 'CZK',
    country: 'CZE',
    name: 'Tomáš Novák',
    alias: 'Můj termínovaný vklad'
  }
];
