/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for adel-private services
 */
@Injectable({
  providedIn: 'root',
})
export class AdelPrivateConfiguration {
  rootUrl: string = 'https://planar-sunrise-282111.appspot.com';
}

export interface AdelPrivateConfigurationInterface {
  rootUrl?: string;
}
