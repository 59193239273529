import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { MatStepperModule } from '@angular/material/stepper';
import { BackgroundComponent } from './components/background/background.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    HeaderComponent,
    ProgressBarComponent,
    BackgroundComponent
  ],
  exports: [HeaderComponent, ProgressBarComponent, BackgroundComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FlexModule,
    ExtendedModule,
    MatStepperModule,
    NgxSkeletonLoaderModule
  ]
})
export class ZaploModule {}
